var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    [
      _c("GeneralTable", {
        ref: "GeneralTable",
        attrs: {
          "module-name": _vm.moduleName,
          "table-name": _vm.tableName,
          "row-key": "c_id",
          url: _vm.url,
          "can-import": _vm.canImport,
          "can-search": _vm.canSearch,
          "can-add": _vm.canAdd,
          "can-edit": _vm.canEdit,
          "can-delete": _vm.canDelete,
          "can-download": _vm.canDownload,
          "can-batch-edit": _vm.canBatchEdit,
          "self-handler-columns": _vm.selfHandlerColumns,
        },
        scopedSlots: _vm._u([
          {
            key: "tableCellValue",
            fn: function (slotProps) {
              return [
                slotProps.code == "b_distributor_change"
                  ? _c(
                      "router-link",
                      {
                        staticClass: "link-type",
                        attrs: {
                          to:
                            "/master/distributorchange/list?did=" +
                            slotProps.row["c_id"],
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              !slotProps.row["b_distributor_change"]
                                ? _vm.$t("button.edit")
                                : slotProps.row["b_distributor_change"]
                            )
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }