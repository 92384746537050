//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GeneralTable from '@/components/GeneralTable';
import { log } from 'util';
import checkPermission from '@/utils/permission'; // 权限判断函数

export default {
  name: 'Index',
  components: {
    GeneralTable: GeneralTable
  },
  data: function data() {
    return {
      moduleName: 'distributor_list_module',
      tableName: 't_distributor',
      url: {
        getUrl: '/distributor',
        method: 'post',
        query: {}
      },
      canSearch: checkPermission('master.distributor.list'),
      //true,
      canAdd: checkPermission('master.distributor.add'),
      //true,
      canEdit: checkPermission('master.distributor.edit'),
      //true,
      canDelete: false,
      //checkPermission('master.distributor.delete'), //true,
      canBatchEdit: false,
      //checkPermission('master.distributor.batch'), //true,
      canDownload: checkPermission('master.distributor.download'),
      //true
      canImport: checkPermission('master.distributor.import_data'),
      selfHandlerColumns: ['b_distributor_change']
    };
  },
  created: function created() {},
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    // 保存离开本页面时的查询条件，当再次进入本页面时回显
    if (this.$refs.GeneralTable) {
      sessionStorage.setItem(window.location.href + '_LIST_QUERY', JSON.stringify(this.$refs.GeneralTable.getListQuery()));
    }
    next();
  },
  methods: {}
};